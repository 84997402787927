.article-card-lists {
  align-items: top;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 276px);
  justify-content: center;
  justify-items: center;
  row-gap: 30px
}

.article-card-lists a {
  display: block;
  cursor: pointer;
  text-decoration: none
}

.article-card-lists .card {
  height: auto;
  width: 100%
}

.article-card-lists .card:hover {
  opacity: .8
}

.article-card-lists .card img {
  aspect-ratio: 1/.56;
  display: block;
  margin-bottom: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%
}

.article-card-lists .card .title {
  word-wrap: break-word;
  color: #121212;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.6
}

@media(max-width:560px) {
  .article-card-lists {
    align-items: top;
    -webkit-column-gap: 10px;
    column-gap: 10px;
    grid-template-columns: calc(50vw - 15px) calc(50vw - 15px);
    justify-items: center;
    row-gap: 20px
  }

  .article-card-lists .card img {
    display: block;
    width: calc(50vw - 15px);
    height: calc((50vw - 15px) * 9 / 16);
    margin-bottom: 10px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .article-card-lists .card .title {
    font-size: 1rem;
    line-height: 1.4
  }
}

.feature {
  position: relative;
  width: 100%
}

.feature .border-first {
  left: 0;
  top: 25px
}

.feature .border-first,
.feature .border-second {
  border: 1px solid #121212;
  height: 460px;
  position: absolute;
  width: 100%;
  z-index: -1
}

.feature .border-second {
  left: 5px;
  top: 30px
}

.feature h1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Roboto Condensed, sans-serif;
  justify-content: center;
  text-align: center
}

.feature h1 span.headding-main {
  background-color: #fff;
  border-bottom: 2px solid #e90059;
  display: inline-block;
  font-size: 2.2rem;
  padding: 0 15px 3px
}

.feature h1 span.headding-sub {
  background-color: #fff;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 400;
  padding-top: 5px
}

.feature p.note {
  margin: 0 0 30px;
  text-align: center;
  width: 100%
}

.feature .grid {
  -webkit-column-gap: 10px;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 390px 390px;
  justify-content: center;
  margin-bottom: 40px;
  row-gap: 10px;
  width: 100%
}

.feature .grid img {
  display: block;
  grid-column: 1/2;
  grid-row: 1/3;
  height: 180px;
  width: auto;
  -o-object-fit: contain;
  object-fit: contain
}

.feature .grid h2 {
  font-size: 18px;
  font-weight: 700;
  grid-column: 2/3;
  grid-row: 1/2;
  width: 100%
}

.feature .grid p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  display: -webkit-box;
  grid-column: 2/3;
  grid-row: 2/3;
  overflow: hidden
}

.feature .btn {
  margin-bottom: 30px;
  text-align: center
}

.feature .btn a {
  background-color: #e90059;
  color: #fff;
  display: inline-block;
  font-family: Roboto Condensed, sans-serif;
  font-size: 1.2rem;
  padding: 15px 0;
  width: 260px
}

.feature .btn a:hover {
  opacity: .8
}

@media(max-width:560px) {
  .feature {
    padding: 10px 20px 0;
  }

  .feature .border-first,
  .feature .border-second {
    height: -webkit-fill-available;
  }

  .feature h1 {
    line-height: 1.1
  }

  .feature h1 span.headding-main {
    font-size: 1.8rem !important
  }

  .feature p.note {
    margin: 0 auto 20px;
    max-width: 100%;
    padding: 0;
    text-align: left
  }

  .feature .grid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 30px 140px;
    margin-bottom: 40px;
    row-gap: 20px;
    width: 100%
  }

  .feature .grid img {
    display: block;
    margin: 0 auto;
    max-width: 100%
  }

  .feature .grid h2 {
    font-size: 21px;
    font-weight: 700;
    grid-column: auto;
    grid-row: auto;
    width: 100%
  }

  .feature .grid p {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    display: -webkit-box;
    font-size: 14px;
    grid-column: auto;
    grid-row: auto;
    overflow: hidden
  }
}

main.top-page .top-above {
  display: grid;
  grid-template-columns: 850px 300px;
  column-gap: 30px;
  margin-bottom: 50px;
}

main.top-page .top-above .headline {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #DEDEDE;
}

main.top-page .top-above section.articles .headline a img {
  display: block;
  width: 100%;
  aspect-ratio: 1/.56;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 6px;
}

main.top-page .top-above section.articles .headline a h2 {
  margin-bottom: 6px;
  font-size: 24px;
  font-weight: bold;
  color: #222222;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

main.top-page .top-above section.articles .headline a time {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #555555;
}

main.top-page .top-above section.articles .headline a p {
  width: 100%;
  margin: 0;
  line-height: 1.7;
}

main.top-page .top-above section.articles .latests {
  margin-bottom: 30px;
}

main.top-page .top-above section.articles .latests a {
  display: grid;
  width: inherit;
  grid-template-columns: 300px 1fr;
  column-gap: 20px;
}

main.top-page .top-above section.articles .latests a .image-info img {
  display: block;
  width: 300px;
  aspect-ratio: 1/.56;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 6px;
}

main.top-page .top-above section.articles .latests a .text-info {
  width: 530px;
}

main.top-page .top-above section.articles .latests a .text-info h2 {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  color: #222222;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

main.top-page .top-above section.articles .latests a .text-info time {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #555555;
}

main.top-page .top-above section.articles .latests a .text-info p {
  margin: 0;
  font-size: 15px;
  color: #1e1e1e;
  line-height: 1.7;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

main.top-page .top-above {
  margin: 0 auto;
}

main.top-page .top-above section {
  width: 100%;
  margin-bottom: 70px;
}

main.top-page .top-above section.feature {
  margin-bottom: 100px;
}

@media(max-width:560px) {
  main.top-page .top-above {
    display: block;
    margin-bottom: 50px;
  }

  main.top-page .top-above section.articles .latests {
    width: 90vw;
    margin: 0 auto 18px;
    padding-bottom: 15px;
    border-bottom: 1px solid #DEDEDE;
  }

  main.top-page .top-above section.articles .latests a {
    display: block;
  }

  main.top-page .top-above section.articles .latests a .image-info {
    position: relative;
    display: block;
    margin-bottom: 10px;
  }

  main.top-page .top-above section.articles .latests a .image-info img {
    display: block;
    width: 100%;
    aspect-ratio: 1/.56;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 5px;
  }

  main.top-page .top-above section.articles .latests a .image-info h2 {
    position: absolute;
    left: 0;
    top: calc(90vw * 0.4 + 0.8px);
    width: 100%;
    height: 30%;
    padding: 7px 10px 4px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.82;
    background-color: rgb(0 0 0 / 80%);
    border-radius: 0 0 5px 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  main.top-page .top-above section.articles .latests a .text-info {
    width: 100%;
  }

  main.top-page .top-above section.articles .latests a .text-info time {
    margin-bottom: 2px;
  }

  main.top-page .top-above {
    width: 90vw;
    margin: 0 auto;
  }

  main.top-page .top-above section {
    width: 100%;
    margin-bottom: 50px;
  }

  main.top-page .top-above section.feature {
    margin-bottom: 100px;
  }
}

main.top-page h1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Roboto Condensed, sans-serif;
  justify-content: center;
  text-align: center
}

main.top-page h1 span.headding-main {
  border-bottom: 2px solid #e90059;
  display: inline-block;
  font-size: 32px;
  line-height: 1.1
}

main.top-page h1 span.headding-sub {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  padding-top: 5px
}

main.top-page p {
  font-family: sans-serif;
  max-width: 100%;
  width: 720px
}

main.top-page .btn {
  text-align: center
}

main.top-page .btn a {
  border: 2px solid #e90059;
  display: inline-block;
  font-family: Roboto Condensed, sans-serif;
  font-size: 20px;
  padding: 15px 0;
  width: 260px
}

main.top-page .btn a:hover {
  background-color: #e90059;
  color: #fff
}

main.top-page .shinsho {
  position: relative
}

main.top-page .shinsho .shinsho-list {
  -webkit-column-gap: 30px;
  column-gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 135px);
  justify-content: center;
  max-width: 100%;
  width: 870px
}

main.top-page .shinsho .shinsho-back {
  border: 2px solid #121212;
  bottom: -20px;
  height: 130px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1
}

@media(max-width:560px) {
  main.top-page {
    padding: 20px 10px 100px;
  }
  main.top-page .shinsho-list {
    -webkit-column-gap: 10px !important;
    column-gap: 10px !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important
  }

  main.top-page .shinsho-list .pc-pnly {
    display: none
  }

  main.top-page .shinsho-back {
    display: none;
  }
}
